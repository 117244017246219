import React from 'react';
import styles from './Tagline.module.css';

const Tagline = () => {
  return (
    <section className={styles.tagline}>
      <h2 className={styles.title}>Collaborate. Analyze. Transform.</h2>
      <p className={styles.description}>
        Join a community of forward-thinkers revolutionizing the way financial analysis is done.
      </p>
    </section>
  );
};

export default Tagline;