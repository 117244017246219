import React from 'react';
import styles from './FeatureCard.module.css';

const FeatureCard = ({ tag, title, description, imageSrc }) => {
  return (
    <article className={styles.card}>
      <div className={styles.content}>
        <div className={styles.header}>
          <span className={styles.tag}>{tag}</span>
          <h3 className={styles.title}>{title}</h3>
        </div>
        <p className={styles.description}>{description}</p>
      </div>
      <img src={imageSrc} alt="" className={styles.image} />
    </article>
  );
};

export default FeatureCard;