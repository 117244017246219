import React from 'react';
import FeatureCard from '../FeatureCard/FeatureCard';
import styles from './FeatureSection.module.css';

const FeatureSection = () => {
  const features = [
    {
      tag: 'Lightning',
      title: 'Fast Insights',
      description: 'Our advanced algorithms and smart systems process data faster than ever, giving you the insights you need at the speed of thought.',
      imageSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/5dfd03b57da3ce859b761e755da841a398facf13a6c324f062405d0bfa64e48f?placeholderIfAbsent=true&apiKey=26bafce145274523b4e2b586033574bf'
    }
  ];

  return (
    <section className={styles.featureSection}>
      {features.map((feature, index) => (
        <FeatureCard key={index} {...feature} />
      ))}
    </section>
  );
};

export default FeatureSection;