import React from "react";
import styles from "./ClarionWaitlist.module.css";

function HeroSection() {
  return (
    <section className={styles.heroSection}>
      <div className={styles.heroContent}>
        <div className={styles.heroTextContent}>
          <h2 className={styles.heroTitle}>Clarion</h2>
          <div className={styles.productBadge}>
            Product by <span className={styles.companyName}>DeepBox</span>
          </div>
        </div>
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/7e58b67c43da0737621bfc15babcfc67a292395ec1a3e6dae19cd7ff49d68953?placeholderIfAbsent=true&apiKey=26bafce145274523b4e2b586033574bf" alt="" className={styles.heroImage} />
      </div>
      <div className={styles.subHeroSection}>
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/64097a0ee45209102b2d2563b0c5f1ad4d2538d6dff310f6bf980cb1759ab795?placeholderIfAbsent=true&apiKey=26bafce145274523b4e2b586033574bf" alt="" className={styles.subHeroImage} />
        <div className={styles.subHeroContent}>
          <h3 className={styles.subHeroTitle}>Clarity, redefined.</h3>
          <p className={styles.subHeroDescription}>
            Revolutionize Your Analysis with AI. Financial Insights, Perfected.
          </p>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;