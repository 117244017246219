import React from 'react';
import styles from './Hero.module.css';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <section className={styles.hero}>
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/7e58b67c43da0737621bfc15babcfc67a292395ec1a3e6dae19cd7ff49d68953?placeholderIfAbsent=true&apiKey=26bafce145274523b4e2b586033574bf" alt="Financial analysis illustration" className={styles.illustration} />
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/64097a0ee45209102b2d2563b0c5f1ad4d2538d6dff310f6bf980cb1759ab795?placeholderIfAbsent=true&apiKey=26bafce145274523b4e2b586033574bf" alt="" className={styles.decorativeImage} />
      <div className={styles.content}>
        <h2 className={styles.title}>Redefine Financial Analysis with Us</h2>
        <p className={styles.description}>
          Where efficiency meets innovation. Financial insights delivered faster, smarter, and sometimes—completely free.
        </p>
        <Link to="/waitlist">
        <button className={styles.ctaButton}>Join the Waitlist</button>
        </Link>
      </div>
    </section>
  );
};

export default Hero;