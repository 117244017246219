import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <h2 className={styles.logo}>DeepBox</h2>
        <nav className={styles.navigation}>
          <ul className={styles.column}>
            <li>Clarion</li>
            <li>Team</li>
            <li>Company</li>
            <li>Careers</li>
          </ul>
          <ul className={styles.column}>
            <li>Twitter</li>
            <li>LinkedIn</li>
          </ul>
          <ul className={styles.column}>
            <li>Privacy Policy</li>
            <li>Usage Policy</li>
            <li>Privacy Choices</li>
          </ul>
        </nav>
      </div>
      <p className={styles.copyright}>
        © 2024 Anagatam Technologies Private Limited (aka DeepBox)
      </p>
    </footer>
  );
};

export default Footer;