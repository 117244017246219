import React from 'react';
import styles from './ValueProposition.module.css';

const ValueProposition = ({ imageSrc, title, description }) => {
  return (
    <article className={styles.valueProposition}>
      <img src={imageSrc} alt="" className={styles.image} />
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{description}</p>
    </article>
  );
};

export default ValueProposition;