import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainContent from './components/MainContent/MainContent';
import ClarionWaitlist from './components/ClarionWaitList/ClarionWaitlist';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainContent />} />
        <Route path="/waitlist" element={<ClarionWaitlist />} />
      </Routes>
    </Router>
  );
}

export default App;
