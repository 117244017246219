import React from "react";
import styles from "./ClarionWaitlist.module.css";

function Footer() {
  const footerLinks = [
    { title: "Clarion", links: ["Team", "Company", "Careers"] },
    { title: "Twitter", links: ["LinkedIn"] },
    { title: "Privacy Policy", links: ["Usage Policy", "Privacy Choices"] }
  ];

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <h2 className={styles.footerLogo}>DeepBox</h2>
        <nav className={styles.footerNav}>
          {footerLinks.map((column, index) => (
            <ul key={index} className={styles.footerColumn}>
              <li>{column.title}</li>
              {column.links.map((link, linkIndex) => (
                <li key={linkIndex}><a href="#">{link}</a></li>
              ))}
            </ul>
          ))}
        </nav>
      </div>
      <p className={styles.copyright}>
        © 2024 Anagatam Technologies Private Limited (aka DeepBox)
      </p>
    </footer>
  );
}

export default Footer;