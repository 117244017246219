import React from 'react';
import styles from './Header.module.css';

const Header = () => {
  return (
    <header className={styles.header}>
      <h1 className={styles.logo}>DeepBox</h1>
      <div className={styles.badge}>
        Product by{" "}
        <span className={styles.badgeHighlight}>DeepBox</span>
      </div>
    </header>
  );
};

export default Header;