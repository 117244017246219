import React, { useState } from "react";
import styles from "./ClarionWaitlist.module.css";
import { validateEmail, validateName, validateOccupation } from "./utils/formValidation";

function WaitlistForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    occupation: "",
    email: ""
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!validateName(formData.firstName)) newErrors.firstName = "First name is required";
    if (!validateName(formData.lastName)) newErrors.lastName = "Last name is required";
    if (!validateOccupation(formData.occupation)) newErrors.occupation = "Please select an occupation";
    if (!validateEmail(formData.email)) newErrors.email = "Please enter a valid email";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Submit form data
      console.log("Form submitted:", formData);
      // Reset form after submission
      setFormData({ firstName: "", lastName: "", occupation: "", email: "" });
    }
  };

  return (
    <section className={styles.waitlistFormSection}>
      <div className={styles.formHeader}>
        <span className={styles.formTag}>Endless</span>
        <h4 className={styles.formTitle}>Clarity starts here.</h4>
      </div>
      <form className={styles.waitlistForm} onSubmit={handleSubmit}>
        <div className={styles.formInputGroup}>
          <label htmlFor="firstName" className="visually-hidden"/>
          <input
            type="text"
            id="firstName"
            name="firstName"
            className={styles.formInput}
            placeholder="Type First Name"
            value={formData.firstName}
            onChange={handleChange}
            aria-invalid={errors.firstName ? "true" : "false"}
          />
          {errors.firstName && <span className={styles.errorMessage}>{errors.firstName}</span>}
          
          <label htmlFor="lastName" className="visually-hidden"/>
          <input
            type="text"
            id="lastName"
            name="lastName"
            className={styles.formInput}
            placeholder="Type Last Name"
            value={formData.lastName}
            onChange={handleChange}
            aria-invalid={errors.lastName ? "true" : "false"}
          />
          {errors.lastName && <span className={styles.errorMessage}>{errors.lastName}</span>}
          
          <label htmlFor="occupation" className="visually-hidden"/>
          <select
            id="occupation"
            name="occupation"
            className={styles.formInput}
            value={formData.occupation}
            onChange={handleChange}
            aria-invalid={errors.occupation ? "true" : "false"}
          >
            <option value="">Select Occupation</option>
            <option value="student">Student</option>
            <option value="⁠executives">⁠Executives (CEO/CTO/CFO)</option>
            <option value="businessman">Businessman/Businesswoman</option>
            <option value="professional">Professionals (Doctor/CAs/CSs/Lawyer)</option>
            <option value="teacher">⁠Teacher/Professor</option>
            <option value="corporate">⁠⁠⁠Corporate Employees</option>
            <option value="other">Other</option>
          </select>
          {errors.occupation && <span className={styles.errorMessage}>{errors.occupation}</span>}
        </div>
        <div className={styles.formSubmitGroup}>
          <label htmlFor="email" className="visually-hidden"/>
          <input
            type="email"
            id="email"
            name="email"
            className={styles.formInput}
            placeholder="Type your Email"
            value={formData.email}
            onChange={handleChange}
            aria-invalid={errors.email ? "true" : "false"}
          />
          {errors.email && <span className={styles.errorMessage}>{errors.email}</span>}
          <button type="submit" className={styles.submitButton}>Join the Waitlist</button>
        </div>
      </form>
    </section>
  );
}

export default WaitlistForm;