import React from 'react';
import styles from './CallToAction.module.css';

const CallToAction = () => {
  return (
    <section className={styles.cta}>
      <h2 className={styles.title}>Let's Build the Future Together</h2>
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/7e58b67c43da0737621bfc15babcfc67a292395ec1a3e6dae19cd7ff49d68953?placeholderIfAbsent=true&apiKey=26bafce145274523b4e2b586033574bf" alt="" className={styles.image} />
      <p className={styles.description}>
        With less time, less cost, and sometimes at no cost at all, you can focus on what matters—making informed decisions that transform the future of finance.
      </p>
      <button className={styles.ctaButton}>Join us today</button>
    </section>
  );
};

export default CallToAction;