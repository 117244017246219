import React from 'react';
import Header from '../Header/Header';
import Hero from '../Hero/Hero';
import Tagline from '../Tagline/Tagline';
import FeatureSection from '../FeatureSection/FeatureSection';
import ValuePropositionSection from '../ValuePropositionSection/ValuePropositionSection';
import CallToAction from '../CallToAction/CallToAction';
import Footer from '../Footer/Footer';
import styles from './MainContent.module.css';

const MainContent = () => {
  return (
    <div className={styles.mainContent}>
      <Header />
      <main>
        <Hero />
        <Tagline />
        <FeatureSection />
        <ValuePropositionSection />
        <CallToAction />
      </main>
      <Footer />
    </div>
  );
};

export default MainContent;