import React from 'react';
import ValueProposition from '../ValueProposition/ValueProposition';
import styles from './ValuePropositionSection.module.css';

const ValuePropositionSection = () => {
  const propositions = [
    {
      imageSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/b83168e5dfbdf07dfea5ff6b98e829ca27b53e965a125703c993ccc93e396b55?placeholderIfAbsent=true&apiKey=26bafce145274523b4e2b586033574bf',
      title: 'Transform Your Business',
      description: "Harness the power of cutting-edge financial tools designed to elevate your decision-making. Whether it's about reducing time, cutting costs, or amplifying accuracy, we've got the solutions to take your financial analysis to the next level."
    },
    {
      imageSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/12147a0e4c7f22c92ebba70f57330db620e0fb9cbfc19636d0d6c67dd526adae?placeholderIfAbsent=true&apiKey=26bafce145274523b4e2b586033574bf',
      title: 'Empower Your Team',
      description: "The future of finance is collaborative. Join forces with us and a community of financial experts, ready to work alongside you to break down barriers and unlock new potential in financial analysis. Together, we'll reimagine what's possible."
    }
  ];

  return (
    <section className={styles.valuePropositionSection}>
      {propositions.map((prop, index) => (
        <ValueProposition key={index} {...prop} />
      ))}
    </section>
  );
};

export default ValuePropositionSection;