import React from "react";
import Header from "./Header";
import HeroSection from "./HeroSection";
import WaitlistForm from "./WaitlistForm";
import Footer from "./Footer";
import styles from "./ClarionWaitlist.module.css";

function ClarionWaitlist() {
  return (
    <div className={styles.joinWaitlist}>
      <Header />
      <main>
        <HeroSection />
        <WaitlistForm />
      </main>
      <Footer />
    </div>
  );
}

export default ClarionWaitlist;